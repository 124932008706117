import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import { GridGallery } from "../components/gallery";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/plans.css";
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class GalleryPage extends React.Component {
  render(){
    const {data} = this.props;
    const gallery = data.contentfulGalleryPage;

    return (
      <Layout>
        <SEO title="Gallery" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4 mb-0">Gallery</h1>
            </div>
            <div className="col-sm-6 text-right mt-auto mb-2">
              {/* <button type="button" className="btn ml-auto" (click)="filter('type', 'All')" [ngclass]="filterClass('All')">All</button>
              <button type="button" className="btn ml-1" (click)="filter('type', '2 Story')" [ngclass]="filterClass('2 Story')">2 Story</button>
              <button type="button" className="btn ml-1" (click)="filter('type', 'Rambler')" [ngclass]="filterClass('Rambler')">Ramblers</button>
              <button type="button" className="btn ml-1" (click)="filter('bonus', true)" [ngclass]="filterClass('bonus')">Bonus Room</button> */}
            </div>
          </div>

          <Row className="row mt-3 mb-4">
            <Col>
              <GridGallery images={gallery.gallery} gid="galleryGrid"/>
            </Col>
          </Row>


    </div>

      </Layout>
    )
  }
};

export default GalleryPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      author
    }
  }
  contentfulGalleryPage(contentfulid: {eq: 1}) {
      id
      gallery {
        fluid {
          ...GatsbyContentfulFluid
        }
        file {
          url
          fileName
          contentType
        }
      }
    }
  }`
